@media (max-device-width: 480px){
    .hidden-mobile{
        display: none;
    }
}

@media (min-device-width: 480px){
    .hidden-desktop{
        display:none;
    }
}

.f3p{
    flex: 0 30%
}
.f2p{
    flex: 0 20%
}

.f1p{
    flex: 0 10%
}

.fr{
    display: flex;
    flex-direction: row;
}

.fc{
    display: flex;
    flex-direction: column;
}

.aic{
    align-items: center;
}

.aifs{
    align-items: flex-start;
}

.jcc{
    justify-content: center;
}

.pr2em{
    padding-right: 2em;
}

.p1em{
    padding: 1em;
}

.fw{
    flex-wrap: wrap;
}

.mw19em{
    max-width: 19em;
}
