.experiences_container{
}

.experience_container{
}

.experience_year{
    padding-right: 3em;
    font-weight: bold;
    font-size: 3em;
}

@media (max-device-width: 480px){
    .experience_year{
        padding-right: 1em;
    }
}
