.project_page{
    position:absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    overflow: hidden;
    overflow-x: hidden;
}


.project_page_text{
    display: flex;
    max-width: 40%;
    height: 80%;
    flex-direction:column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: justify;
    text-justify: inter-word;
    padding-left: 5em;
    padding-top: 5em;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.project_page_text::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.project_page_title{
    max-width: 100%;
    font-size: 4em;
    height: 3em;
    text-align: start;
}

.project_page_description{
    max-width: 90%;
    color: #231f20;
    font-size: 1.5em;
}

.project_page_pictures{
    height: 100%;
    transition: ease-in-out 0.5s;
    width:50%;
    min-width: 0;
    min-height: 0;
    overflow-y: scroll;
    text-align: center;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.project_page_pictures::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.project_page_picture{
    width: 100%;
    max-height: none;
    object-fit: cover;
    min-width: 0;
    display: block; /* remove the space between each picture*/
    min-height: 0;
}

.empty{
    margin: 1em;
    border-radius: 0.5rem;
    background-color: rgb(156, 156, 156);
}

.project_page_back{
    position: absolute;
    bottom: 0;
    padding: 1em;
    margin-bottom: 2em;
    font-size: 1.5em;
}


@media (max-device-width: 480px){
    .project_page{
        overflow: visible;
    }

    .project_page_text{
        height: auto;
        max-width: 75%;
        align-items: center;
        padding: 0;
        margin: 0 auto;
        margin-bottom: 1em;
        overflow-y: visible;
    }

    .project_page_title{
        max-width: 100%;
        font-size: 3em;
        height: 3em;
    }

    .project_page_description{
        max-width: 100%;
    }

    .project_page_pictures{
        width:100%;
        height: auto;
        overflow-y:visible;
    }

    .project_page_picture{
        max-width: 75%;
        margin: 0 auto;
    }

    .project_page_back{
        max-width: 75%;
        position: relative;
        padding: 1em;
        font-size: 1em;
    }
    
}