
.title_bar{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* height: 25em; */
    align-items: center;
    justify-content: center;
    /* justify-content: space-between; */
    margin-bottom: 1em;
}

.title_bar_title h1{
    text-decoration: none;
    size: inherit;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
    font-weight: normal;
}

.title_bar h2{
    /* font-size: 1em; */
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0;
}

.title_bar_controls{
    transition: ease-in-out 2s;
    text-justify: distribute;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content:  center;
    /* margin-bottom: 25px;
    margin-top: 25px; */
    height: 200px;

}


.nav_control
{
    font-size: 3em;
    word-spacing: 2em;
   /* letter-spacing: 0.5em; */
}

a .nav_control,
.nav_control a{
    text-decoration: none;
    transition: ease-in-out 0.25s;
    color: black;
}

a .nav_control:visited,
.nav_control a:visited{
    color: black;
}

a .nav_control:hover,
.nav_control a:hover{
    color: #888888;
}

.no_long_spaces{
    word-spacing: initial;
}

@media (max-device-width: 480px){
    .nav_control
    {
        font-size: 0.5em;
        word-spacing: 0.5em;
    /* letter-spacing: 0.5em; */
    }

}