.about_title{
    display:flex;
    justify-content: center;
    padding: 2em;
}

.about_experiences{
    /* margin-left: 30em; */
    /* margin-top: 10em; */
    margin-top: 2em;
}

.about_content .contact{
    /* margin-left: 2em; */
}

.about_content{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-left: 40%;
}

.about_back{
    padding: 1em;
    padding-left: 10em;
    padding-bottom: 2em;
    font-size: 1.5em;
}

@media (max-device-width: 480px){
    .about_experiences{
        /* margin: 2em; */
    }

    .about_content{
        padding-left: 2em;
        /* margin: 2em; */
        margin: 0 auto;
    }

    .about_back{
        padding: 1em;
        padding-bottom: 2em;
        font-size: 1em;
    }
    
    .about_back{
        padding-left: 1em;
    }

}