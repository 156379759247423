body{
    color: #231f20
}

.App {
  text-align: center;
}

.button_back{
  text-decoration: none;
  color: #231f20;
  font-size: 1.5em;
  transition: ease-in-out 0.75s;
}

.button_back:hover{
  transform: translate( var(--data-move));
}

.button_back a{
  text-decoration: none;
  color: #231f20;

}

.button_back_element{
  transition: ease-in-out 0.75s;
}

.button_back_element:hover{
  transform: translate( var(--data-move))
}

@media (max-device-width: 480px){
.hidden_mobile{
  display: none;
}
}
