.projects_list{
    display: flex;
    flex-wrap: wrap;    
    align-items: flex-start;
    justify-content:space-evenly;
    margin-top: 2em;
}

.project_tile_display{
    background-image: var(--data-picture);
    background-position: center;
    background-size: cover;
    transition: ease-in-out 0.5s;
    height: 30em;
}

.project_tile{
    flex: 0 21%;
    padding-bottom: 2em;
}

@keyframes project_tiles_hover {
    0%{
        transform: rotate3d(0,0,0, 10deg);
    }
    50%{
        /* transform: rotate3d(-1,-0.6,0, 180deg); */
        transform: rotate3d(0,0,0, 10deg);

        /* transform: translate3d(0, 0, 50px); */
    }
    100%{
        transform: rotate3d(0,0,0, 10deg);
    }
}

.project_tile:hover{
    animation: project_tiles_hover 1s ease-in-out forwards;
    /* transform: rotate3d(-1,-0.1,0, 10deg); */
}

.project_overlay{
    height:100%;
    width:100%;
    transition: ease-in-out 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    text-decoration: none;
    opacity: 0;
    background-color:rgba(220,220,220,0.4);
    color: #231f20;

}


.project_overlay:hover{
    opacity: 1;
    cursor: pointer;

}


a{
    opacity: 1;
    text-decoration: none;

}

@media (max-device-width: 480px){
    .project_tile{
        flex: 0 60%;
     }

     .project_tile_display{
        height: 23em;
    }
    
}