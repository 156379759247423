.title_bar_title{
    font-size: 6em;
    font-weight: 500;
    /* margin-bottom: 25px;
    margin-top: 25px; */
    height: 200px;
    display: inline-block;
    transition: ease-in-out 1000s;
    transform: translate( var(--translate-x), var(--translate-y));
}


.title_bar_title a{
    color: black;
    text-decoration: none;
}

.title_bar_title a:visited{
    color: black;
}

.movable{
    position: absolute;
}

.positioned{
    position: relative;
}

.title_bar_entry{
    /* position:initial; */
    color: var( --color);
    z-index: var( --index);
    transition: ease-in-out 1000ms;
    transform: translate( var(--translate-x), var(--translate-y));
    /* animation: move 1s ease-in-out forwards; */
}


@media (max-device-width: 480px){
    
}