@media (max-device-width: 480px){
    .title_bar{

    }
    
    .title_bar_title{
        font-size: 3em;
        height: auto;
        margin: auto;
        margin-top: 1em;
    }

    .nav_controls{
        height: auto;
        margin: auto;

    }

    .nav_control{
        font-size: 1.75em;
    }
    
}